<template>
  <div>
    <!-- SECTION: Table Container Card -->
    <b-card
      no-body
      class="mb-1 box-shadow-0 border p-1"
      style="max-height: calc(100vh - 150px); height: 100%;"
    >
      <!-- SECTION: Filters -->
      <UpdateAccountsPayableFilters
        :search-text-filter="searchTextFilter"
        :customer-code-filter.sync="customerCodeFilter"
        :from-date-filter.sync="fromDateFilter"
        :to-date-filter.sync="toDateFilter"
        :total-opening-balance="accountPayable.totalOpeningBalance"
        :total-closing-balance="accountPayable.totalClosingBalance"
        :total-receivables="accountPayable.totalReceivables"
        :total-trade-creditor="accountPayable.totalTradeCreditor"
        :total-unpaid-commission="accountPayable.totalUnpaidCommission"
        @refetch-data-with-filters="onRefetchDataWithFilters"
        @date-filters="dateFilters"
        @refetch-data-without-filters="clearFilter"
      />
      <!-- :empty-filter="isEmptyFilter" -->
      <!-- @reset="clearFilter" -->
      <!-- ANCHOR Table Top -->

      <!-- ANCHOR Table -->
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
        style="height: calc(100vh - 450px);"
      >
        <b-table
          ref="refAccountPayableDetailTable"
          style="max-height: 100%"
          :sticky-header="true"
          class="position-relative"
          :items="onRefetchDataWithFilters"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('noMatchingResult')"
          no-border-collapse
        >

          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column})`]="data"
          >
            <span
              :key="column"
              class="text-dark text-nowrap"
            >
              {{ data.label }}
              <!-- {{ $t(`accountsPayable.${data.label}`) }} -->
            </span>
          </template>
          <!-- <template
            v-for="column in tableColumns"
            v-slot:[`cell(${column})`]="data"
          >
            <div
              :key="column"
              class="text-nowrap"
              style="min-width: 100px;"
            >
              <b-form-input
                v-model="data.item[column]"
              />
            </div>
          </template> -->

          <template #cell(no)="data">
            <div>
              {{ data.index + 1 }}
            </div>
          </template>

          <template #cell(bookingDate)="{ item }">
            <div class="text-nowrap">
              {{ convertISODateTime(item.bookingDate).date }}
            </div>
          </template>

          <template #cell(issueDate)="data">
            <div
              class="text-nowrap"
              style="min-width: 120px;"
            >
              <b-form-input
                v-model="data.item.issueDate"
              />
            </div>
            <!-- {{ getDate(issueDate) }} -->
          </template>

          <template #cell(booker)="data">
            <div
              class="text-nowrap"
              style="min-width: 120px;"
            >
              <b-form-input
                v-model="data.item.booker"
              />
            </div>
          </template>

          <template #cell(signBook)="{ item: { signBook } }">
            <div class="text-right text-nowrap">
              {{ formatVnCurrency(signBook) }}
            </div>
          </template>

          <!-- ANCHOR priceTicket -->
          <template #cell(priceTicket)="data">
            <div
              class="text-nowrap"
              style="min-width: 120px;"
            >
              <b-form-input
                v-model.number="data.item.priceTicket"
              />
            </div>
          </template>

          <!-- ANCHOR collection fee -->
          <template #cell(collectionFee)="data">
            <div
              class="text-nowrap"
              style="min-width: 120px;"
            >
              <b-form-input
                v-model.number="data.item.collectionFee"
              />
            </div>
          </template>

          <!-- ANCHOR tax -->
          <template #cell(tax)="data">
            <div
              class="text-nowrap"
              style="min-width: 120px;"
            >
              <b-form-input
                v-model.number="data.item.tax"
              />
            </div>
          </template>

          <!-- ANCHOR fee -->
          <template #cell(fee)="data">
            <div
              class="text-nowrap"
              style="min-width: 120px;"
            >
              <b-form-input
                v-model="data.item.fee"
              />
            </div>
          </template>

          <!-- ANCHOR feeService -->
          <template #cell(feeService)="data">
            <div
              class="text-nowrap"
              style="min-width: 120px;"
            >
              <b-form-input
                v-model.number="data.item.feeService"
              />
            </div>
          </template>

          <!-- ANCHOR commissionPrepaid -->
          <template #cell(commissionPrepaid)="data">
            <div
              class="text-nowrap"
              style="min-width: 120px;"
            >
              <b-form-input
                v-model.number="data.item.commissionPrepaid"
              />
            </div>
          </template>

          <!-- ANCHOR unpaidCommission -->
          <template #cell(unpaidCommission)="data">
            <div
              class="text-nowrap"
              style="min-width: 120px;"
            >
              <b-form-input
                v-model.number="data.item.unpaidCommission"
              />
            </div>
          </template>

          <!-- ANCHOR tradeCreditors -->tradeCreditors
          <template #cell(tradeCreditors)="data">
            <div
              class="text-nowrap"
              style="min-width: 120px;"
            >
              <b-form-input
                v-model="data.item.tradeCreditors"
              />
            </div>
          </template>

          <!-- ANCHOR receivables -->receivables
          <template #cell(receivables)="data">
            <div
              class="text-nowrap"
              style="min-width: 120px;"
            >
              <b-form-input
                v-model="data.item.receivables"
              />
            </div>
          </template>

          <!-- ANCHOR totalPayment -->
          <template #cell(totalPayment)="{ item: { totalPayment } }">
            <div class="font-weight-bold text-right">
              {{ formatVnCurrency(totalPayment) }}
            </div>
          </template>

        </b-table>
      </b-overlay>

      <!-- ANCHOR Table Footer -->
      <div class="d-flex-center justify-content-between">
        <div />
        <div>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="mr-1"
            :class="isMobileView ? '' : 'width-150'"
            variant="outline-danger"
            pill
            @click="$router.go(-1)"
          >
            <span
              class="align-middle"
              style=""
            >{{ $t('cancel') }}
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient"
            :class="isMobileView ? '' : 'width-150'"
            pill
            @click="updateSaleReportHandle"
          >
            <!-- :disabled="emptyFilter" -->
            <!-- <feather-icon
              icon="SearchIcon"
              class="mr-50"
            /> -->
            <span class="align-middle">{{ $t('save') }}</span>
          </b-button>
        </div>
        <!-- ANCHOR Export -->
        <b-button
          class="px-lg-1 px-sm-75 py-50"
          variant="flat-info"
          :disabled="!canAccess('acc_payable.exportAccpayble')"
          @click="confirmExport()"
        >
          <span
            class="text-nowrap"
          >
            <img
              src="@icons/export-excel.svg"
              class="cursor-pointer"
              size="16"
            >
            <span class="d-none d-sm-inline ml-50">{{ $t('export') }}</span>
          </span>
        </b-button>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BTable, BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import {
  onUnmounted,
  ref,
} from '@vue/composition-api'
import { createPopper } from '@popperjs/core'

import store from '@/store'
import { sizePerPageOptions } from '@/constants/selectOptions'
import { useRouter } from '@/@core/utils/utils'

import {
  formatCurrency, convertISODateTime, getDate, formatVnCurrency,
} from '@core/utils/filter'

import accountsPayableStoreModule from '@accountsPayable/accountsPayableStoreModule'
import useAccountsPayableHandle from '@accountsPayable/useAccountsPayableHandle'

import useToast from '@useToast'

import UpdateAccountsPayableFilters from './UpdateAccountsPayableFilters.vue'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BFormInput,
    BOverlay,
    UpdateAccountsPayableFilters,
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],

      })
      return () => popper.destroy()
    },
  },
  setup() {
    const { toastError } = useToast()
    const tableColumns = ref(['no', 'airline', 'source', 'bookingCode', 'paxName', 'paxType', 'ticketNumber', 'type', 'flightType', 'trips', 'bookingClass', 'fareBasisCode', 'bookingDate', 'issueDate', 'booker', 'signBook', 'currency', 'priceTicket', 'tax', 'collectionFee', 'fee', 'feeService', 'commissionPrepaid', 'unpaidCommission', 'tradeCreditors', 'receivables', 'totalPayment'])

    const accountPayable = ref({})

    const ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME = 'app-account-payable'

    // Register module
    if (!store.hasModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME, accountsPayableStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME)
    })

    const { route } = useRouter()
    const { query } = route.value

    const startDate = ref(null)
    const endDate = ref(null)

    const {
      refetchData,
      sizePerPage,
      dataMeta,
      totalAccountsPayable,
      sortBy,
      isSortDirDesc,
      customerCodeFilter, fromDateFilter, toDateFilter,
      fetchAccountsPayable,
      // isEmptyFilter,
      loading,
      clearFilter,
      searchTextFilter, currentPage, totalOpeningBalance, totalClosingBalance, accruedDebt, accruedCredit,
      updateSaleReport,
      refAccountPayableDetailTable,
      fetchAccountPayableDetail,
      exportAccountPayableDetail,
    } = useAccountsPayableHandle()

    function openBookClosingModal() {
      this.$bvModal.show('book-closing-modal')
    }

    function updateSaleReportHandle() {
      // FIXME
      toastError({ title: 'featureInDevelopment' })
      return
      // eslint-disable-next-line no-unreachable
      this.updateSaleReport(accountPayable.value.items)
    }

    function onRefetchDataWithFilters(data, callback = () => {}) {
      loading.value = true
      store.dispatch('app-account-payable/accountPayableDetail', {
        startDate: data.startDate || query.startDate,
        endDate: data.endDate || query.endDate,
        agencyCode: data.agencyCode || null,
        customerCode: data.customerCode || null,
        type: query.typeCode,
        code: query.code,
        searchText: '',
      })
        .then(response => {
          accountPayable.value = response.data
          callback(response.data.items)
        })
        .catch(error => {
          if (error.response.status === 404) {
            accountPayable.value = undefined
          }
        }).finally(() => {
          loading.value = false
        })
    }

    const dateFilters = data => {
      startDate.value = data.startDate
      endDate.value = data.endDate
    }

    function confirmExport() {
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            exportAccountPayableDetail({
              type: query.typeCode,
              code: query.code,
              startDate: query.startDate,
              endDate: query.endDate,
              searchText: '',
            })
          }
        })
    }
    return {
      refAccountPayableDetailTable,
      searchTextFilter,
      totalOpeningBalance,
      totalClosingBalance,
      accruedDebt,
      accruedCredit,
      loading,
      refetchData,
      sizePerPage,
      sizePerPageOptions,
      dataMeta,
      totalAccountsPayable,
      tableColumns,
      sortBy,
      isSortDirDesc,
      customerCodeFilter,
      fromDateFilter,
      toDateFilter,
      fetchAccountsPayable,
      // isEmptyFilter,
      currentPage,
      formatCurrency,
      openBookClosingModal,

      convertISODateTime,
      formatVnCurrency,
      getDate,

      route,

      accountPayable,
      query,

      startDate,
      endDate,

      dateFilters,
      clearFilter,

      updateSaleReportHandle,
      updateSaleReport,
      fetchAccountPayableDetail,

      onRefetchDataWithFilters,
      confirmExport,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.per-page-selector ::v-deep {
  width: 100%;
  max-width: 90px;

  .vs__selected-options {
    flex-wrap: nowrap
  }
}
</style>
