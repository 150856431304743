<template>
  <div>
    <b-row class="mb-1">
      <b-col
        cols="auto"
        md="auto"
        class="mt-1 mt-md-0 flex-grow-1"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            :value="searchTextFilter"
            type="search"
            maxlength="255"
            debounce="500"
            trim
            :placeholder="$t('accountsPayable.searchPlaceholder')"
            @input="(value) => handleDebounceSearchText(value)"
          />
        </b-input-group>
      </b-col>

      <div
        class="d-flex align-items-start mr-1"
        :class="isMobileView ? 'w-100 justify-content-center mt-1' : ''"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mr-1 width-150"
          pill
          :disabled="emptyFilter"
          @click="onUpdateFilters"
        >
          <feather-icon
            icon="SearchIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('search') }}</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="btn-white width-150"
          pill
          :disabled="emptyFilter"
        >
          <!-- @click="$emit('reset'), resetFilter()" -->
          <feather-icon
            icon="RefreshCwIcon"
            class="mr-50"
            style="color: cadetblue"
            @click="onClearFilters"
          />
          <span
            class="align-middle text-gradient"
            style=""
          >{{ $t('clear') }}
          </span>
        </b-button>
      </div>
    </b-row>

    <b-row>
      <!-- ANCHOR START DATE -->
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label-class="h5"
          :label="$t('topup.startDate')"
        >
          <b-form-datepicker
            v-model="startDate"
            style="font-size: 1rem;"
            :today-button="!endDate"
            reset-button
            close-button
            :locale="$i18n.locale"
            :max="maxStartDate"
            :placeholder="$t('topup.placeholderSelectDate')"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR END DATE -->
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label-class="h5"
          :label="$t('topup.endDate')"
        >
          <b-form-datepicker
            v-model="endDate"
            style="font-size: 1rem;"
            today-button
            reset-button
            close-button
            :locale="$i18n.locale"
            :min="minEndDate"
            :placeholder="$t('topup.placeholderSelectDate')"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="3"
      >
        <!-- ANCHOR AGENCY CODE -->
        <b-form-group
          label-class="h5"
          :label="$t('agency.agencyCode')"
        >
          <b-form-input
            v-model.trim="agencyCode"
            :placeholder="$t('saleReport.phAgencyCode')"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="3"
      >
        <!-- ANCHOR AGENCY CODE -->
        <b-form-group
          label-class="h5"
          :label="$t('customer.code')"
        >
          <b-form-input
            v-model.trim="customerCode"
            :placeholder="$t('customer.placeholderCode')"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-0">
      <b-col
        cols="12"
        md="auto"
      >
        <b-form-group
          :label="$t('accountsPayable.openingBalance')"
          label-for="openingBalance"
        >
          <b-form-input
            id="openingBalance"
            :value="formatCurrency(totalOpeningBalance)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <b-form-group
          :label="$t('accountsPayable.closingBalance')"
          label-for="closingBalance"
        >
          <b-form-input
            id="closingBalance"
            :value="formatCurrency(totalClosingBalance)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <b-form-group
          :label="$t('accountsPayable.receivables')"
          label-for="receivables"
        >
          <b-form-input
            id="receivables"
            :value="formatCurrency(totalReceivables)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <b-form-group
          :label="$t('accountsPayable.tradeCreditors')"
          label-for="tradeCreditors"
        >
          <b-form-input
            id="tradeCreditors"
            :value="formatCurrency(totalTradeCreditors)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <b-form-group
          :label="$t('accountsPayable.unpaidCommission')"
          label-for="unpaidCommission"
        >
          <b-form-input
            id="unpaidCommission"
            :value="formatCurrency(totalUnpaidCommission)"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormDatepicker,
  // BInputGroupAppend,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { debounce } from 'lodash'
import { ref, computed } from '@vue/composition-api'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormDatepicker,

  },
  props: {
    emptyFilter: {
      type: Boolean,
      default: null,
    },
    fromDateFilter: {
      type: String,
      default: null,
    },
    toDateFilter: {
      type: String,
      default: null,
    },
    customerCodeFilter: {
      type: String,
      default: null,
    },
    totalOpeningBalance: {
      type: Number,
      default: null,
    },
    totalClosingBalance: {
      type: Number,
      default: null,
    },
    totalReceivables: {
      type: Number,
      default: null,
    },
    totalTradeCreditors: {
      type: Number,
      default: null,
    },
    totalUnpaidCommission: {
      type: Number,
      default: null,
    },

    searchTextFilter: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const rangeDate = ref([])

    const now = convertISODateTime(new Date()).dateFilter
    const startDate = ref(now)
    const endDate = ref(now)
    const agencyCode = ref(null)
    const customerCode = ref(null)

    const maxStartDate = computed(() => (endDate.value ? endDate.value : now))
    const minEndDate = computed(() => (startDate.value ? startDate.value : undefined))

    const handleDebounceSearchText = debounce(searchText => {
      emit('updateSearchTextFilter', searchText)
    }, 500)

    const customerCodeOptions = ref([1, 2, 3])

    function openFindBookingByPnrCode() {
      this.$bvModal.show('find-booking-by-pnr-code-modal')
    }

    function changeRangeDate(value) {
      if (!value) return
      const sepSign = this.$i18n.locale === 'vi' ? ' đến ' : ' to '
      const dateRange = value.split(sepSign)
      if (dateRange.length > 1) {
        this.$emit('update:fromDateFilter', dateRange[0])
        this.$emit('update:toDateFilter', dateRange[1])
      } else if (dateRange.length === 1) {
        this.$emit('update:startDateFilter', dateRange[0])
        this.$emit('update:endDateFilter', dateRange[0])
      }
    }

    const onClearFilters = () => {
      startDate.value = now
      endDate.value = now
      emit('refetch-data-without-filters')
      emit('date-filters')
    }

    const onUpdateFilters = () => {
      const filtersData = {
        startDate: startDate.value || now,
        endDate: startDate.value || now,
        agencyCode: agencyCode.value || null,
        customerCode: customerCode.value || null,
      }
      emit('refetch-data-with-filters', filtersData)
      emit('date-filters', filtersData)
    }

    return {
      Vietnamese,
      handleDebounceSearchText,
      openFindBookingByPnrCode,
      customerCodeOptions,
      changeRangeDate,
      rangeDate,
      formatCurrency,

      onUpdateFilters,
      onClearFilters,

      startDate,
      endDate,
      maxStartDate,
      minEndDate,
      agencyCode,
      customerCode,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
#range-date ::v-deep {
  input.form-control.input {
    border-radius: 0.357rem 0 0 0.357rem;
    border-right: none;
  }
}
</style>
