var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "mt-1 mt-md-0 flex-grow-1",
    attrs: {
      "cols": "auto",
      "md": "auto"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "value": _vm.searchTextFilter,
      "type": "search",
      "maxlength": "255",
      "debounce": "500",
      "trim": "",
      "placeholder": _vm.$t('accountsPayable.searchPlaceholder')
    },
    on: {
      "input": function input(value) {
        return _vm.handleDebounceSearchText(value);
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-start mr-1",
    class: _vm.isMobileView ? 'w-100 justify-content-center mt-1' : ''
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-gradient mr-1 width-150",
    attrs: {
      "pill": "",
      "disabled": _vm.emptyFilter
    },
    on: {
      "click": _vm.onUpdateFilters
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "SearchIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(_vm._s(_vm.$t('search')))])], 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-white width-150",
    attrs: {
      "pill": "",
      "disabled": _vm.emptyFilter
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    staticStyle: {
      "color": "cadetblue"
    },
    attrs: {
      "icon": "RefreshCwIcon"
    },
    on: {
      "click": _vm.onClearFilters
    }
  }), _c('span', {
    staticClass: "align-middle text-gradient"
  }, [_vm._v(_vm._s(_vm.$t('clear')) + " ")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5",
      "label": _vm.$t('topup.startDate')
    }
  }, [_c('b-form-datepicker', {
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "today-button": !_vm.endDate,
      "reset-button": "",
      "close-button": "",
      "locale": _vm.$i18n.locale,
      "max": _vm.maxStartDate,
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "date-format-options": {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5",
      "label": _vm.$t('topup.endDate')
    }
  }, [_c('b-form-datepicker', {
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "today-button": "",
      "reset-button": "",
      "close-button": "",
      "locale": _vm.$i18n.locale,
      "min": _vm.minEndDate,
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "date-format-options": {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5",
      "label": _vm.$t('agency.agencyCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('saleReport.phAgencyCode')
    },
    model: {
      value: _vm.agencyCode,
      callback: function callback($$v) {
        _vm.agencyCode = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "agencyCode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5",
      "label": _vm.$t('customer.code')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('customer.placeholderCode')
    },
    model: {
      value: _vm.customerCode,
      callback: function callback($$v) {
        _vm.customerCode = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "customerCode"
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-0"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('accountsPayable.openingBalance'),
      "label-for": "openingBalance"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "openingBalance",
      "value": _vm.formatCurrency(_vm.totalOpeningBalance),
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('accountsPayable.closingBalance'),
      "label-for": "closingBalance"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "closingBalance",
      "value": _vm.formatCurrency(_vm.totalClosingBalance),
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('accountsPayable.receivables'),
      "label-for": "receivables"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "receivables",
      "value": _vm.formatCurrency(_vm.totalReceivables),
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('accountsPayable.tradeCreditors'),
      "label-for": "tradeCreditors"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "tradeCreditors",
      "value": _vm.formatCurrency(_vm.totalTradeCreditors),
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('accountsPayable.unpaidCommission'),
      "label-for": "unpaidCommission"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "unpaidCommission",
      "value": _vm.formatCurrency(_vm.totalUnpaidCommission),
      "disabled": ""
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }