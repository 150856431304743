var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "mb-1 box-shadow-0 border p-1",
    staticStyle: {
      "max-height": "calc(100vh - 150px)",
      "height": "100%"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('UpdateAccountsPayableFilters', {
    attrs: {
      "search-text-filter": _vm.searchTextFilter,
      "customer-code-filter": _vm.customerCodeFilter,
      "from-date-filter": _vm.fromDateFilter,
      "to-date-filter": _vm.toDateFilter,
      "total-opening-balance": _vm.accountPayable.totalOpeningBalance,
      "total-closing-balance": _vm.accountPayable.totalClosingBalance,
      "total-receivables": _vm.accountPayable.totalReceivables,
      "total-trade-creditor": _vm.accountPayable.totalTradeCreditor,
      "total-unpaid-commission": _vm.accountPayable.totalUnpaidCommission
    },
    on: {
      "update:customerCodeFilter": function updateCustomerCodeFilter($event) {
        _vm.customerCodeFilter = $event;
      },
      "update:customer-code-filter": function updateCustomerCodeFilter($event) {
        _vm.customerCodeFilter = $event;
      },
      "update:fromDateFilter": function updateFromDateFilter($event) {
        _vm.fromDateFilter = $event;
      },
      "update:from-date-filter": function updateFromDateFilter($event) {
        _vm.fromDateFilter = $event;
      },
      "update:toDateFilter": function updateToDateFilter($event) {
        _vm.toDateFilter = $event;
      },
      "update:to-date-filter": function updateToDateFilter($event) {
        _vm.toDateFilter = $event;
      },
      "refetch-data-with-filters": _vm.onRefetchDataWithFilters,
      "date-filters": _vm.dateFilters,
      "refetch-data-without-filters": _vm.clearFilter
    }
  }), _c('b-overlay', {
    staticStyle: {
      "height": "calc(100vh - 450px)"
    },
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refAccountPayableDetailTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "100%"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.onRefetchDataWithFilters,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(data.label) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('div', [_vm._v(" " + _vm._s(data.index + 1) + " ")])];
      }
    }, {
      key: "cell(bookingDate)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.bookingDate).date) + " ")])];
      }
    }, {
      key: "cell(issueDate)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "min-width": "120px"
          }
        }, [_c('b-form-input', {
          model: {
            value: data.item.issueDate,
            callback: function callback($$v) {
              _vm.$set(data.item, "issueDate", $$v);
            },
            expression: "data.item.issueDate"
          }
        })], 1)];
      }
    }, {
      key: "cell(booker)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "min-width": "120px"
          }
        }, [_c('b-form-input', {
          model: {
            value: data.item.booker,
            callback: function callback($$v) {
              _vm.$set(data.item, "booker", $$v);
            },
            expression: "data.item.booker"
          }
        })], 1)];
      }
    }, {
      key: "cell(signBook)",
      fn: function fn(_ref2) {
        var signBook = _ref2.item.signBook;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(signBook)) + " ")])];
      }
    }, {
      key: "cell(priceTicket)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "min-width": "120px"
          }
        }, [_c('b-form-input', {
          model: {
            value: data.item.priceTicket,
            callback: function callback($$v) {
              _vm.$set(data.item, "priceTicket", _vm._n($$v));
            },
            expression: "data.item.priceTicket"
          }
        })], 1)];
      }
    }, {
      key: "cell(collectionFee)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "min-width": "120px"
          }
        }, [_c('b-form-input', {
          model: {
            value: data.item.collectionFee,
            callback: function callback($$v) {
              _vm.$set(data.item, "collectionFee", _vm._n($$v));
            },
            expression: "data.item.collectionFee"
          }
        })], 1)];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "min-width": "120px"
          }
        }, [_c('b-form-input', {
          model: {
            value: data.item.tax,
            callback: function callback($$v) {
              _vm.$set(data.item, "tax", _vm._n($$v));
            },
            expression: "data.item.tax"
          }
        })], 1)];
      }
    }, {
      key: "cell(fee)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "min-width": "120px"
          }
        }, [_c('b-form-input', {
          model: {
            value: data.item.fee,
            callback: function callback($$v) {
              _vm.$set(data.item, "fee", $$v);
            },
            expression: "data.item.fee"
          }
        })], 1)];
      }
    }, {
      key: "cell(feeService)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "min-width": "120px"
          }
        }, [_c('b-form-input', {
          model: {
            value: data.item.feeService,
            callback: function callback($$v) {
              _vm.$set(data.item, "feeService", _vm._n($$v));
            },
            expression: "data.item.feeService"
          }
        })], 1)];
      }
    }, {
      key: "cell(commissionPrepaid)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "min-width": "120px"
          }
        }, [_c('b-form-input', {
          model: {
            value: data.item.commissionPrepaid,
            callback: function callback($$v) {
              _vm.$set(data.item, "commissionPrepaid", _vm._n($$v));
            },
            expression: "data.item.commissionPrepaid"
          }
        })], 1)];
      }
    }, {
      key: "cell(unpaidCommission)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "min-width": "120px"
          }
        }, [_c('b-form-input', {
          model: {
            value: data.item.unpaidCommission,
            callback: function callback($$v) {
              _vm.$set(data.item, "unpaidCommission", _vm._n($$v));
            },
            expression: "data.item.unpaidCommission"
          }
        })], 1)];
      }
    }, {
      key: "cell(tradeCreditors)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "min-width": "120px"
          }
        }, [_c('b-form-input', {
          model: {
            value: data.item.tradeCreditors,
            callback: function callback($$v) {
              _vm.$set(data.item, "tradeCreditors", $$v);
            },
            expression: "data.item.tradeCreditors"
          }
        })], 1)];
      }
    }, {
      key: "cell(receivables)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "min-width": "120px"
          }
        }, [_c('b-form-input', {
          model: {
            value: data.item.receivables,
            callback: function callback($$v) {
              _vm.$set(data.item, "receivables", $$v);
            },
            expression: "data.item.receivables"
          }
        })], 1)];
      }
    }, {
      key: "cell(totalPayment)",
      fn: function fn(_ref3) {
        var totalPayment = _ref3.item.totalPayment;
        return [_c('div', {
          staticClass: "font-weight-bold text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(totalPayment)) + " ")])];
      }
    }], null, true)
  }, [_vm._v("tradeCreditors "), _vm._v("receivables ")])], 1), _c('div', {
    staticClass: "d-flex-center justify-content-between"
  }, [_c('div'), _c('div', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    class: _vm.isMobileView ? '' : 'width-150',
    attrs: {
      "variant": "outline-danger",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "align-middle"
  }, [_vm._v(_vm._s(_vm.$t('cancel')) + " ")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-gradient",
    class: _vm.isMobileView ? '' : 'width-150',
    attrs: {
      "pill": ""
    },
    on: {
      "click": _vm.updateSaleReportHandle
    }
  }, [_c('span', {
    staticClass: "align-middle"
  }, [_vm._v(_vm._s(_vm.$t('save')))])])], 1), _c('b-button', {
    staticClass: "px-lg-1 px-sm-75 py-50",
    attrs: {
      "variant": "flat-info",
      "disabled": !_vm.canAccess('acc_payable.exportAccpayble')
    },
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('img', {
    staticClass: "cursor-pointer",
    attrs: {
      "src": require("@icons/export-excel.svg"),
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline ml-50"
  }, [_vm._v(_vm._s(_vm.$t('export')))])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }